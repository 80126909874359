import React from 'react'
import {
  VisionSectionWrapper,
  VisionSectionContent,
  CheckWrapper,
  VisionImageWrapper,
  VisionSectionContentWrapper
} from './vision.styled'
import { Typography } from '@material-ui/core'
import MainBg from '../../../../assets/probisma-vision.png'

const VisionSection = () => (
  <VisionSectionWrapper id={'vision'}>
    <VisionImageWrapper img={MainBg} />
    <VisionSectionContentWrapper>
      <VisionSectionContent>
        <Typography variant="h5" gutterBottom>
          Probisma Vision
        </Typography>

        <Typography variant="body2" paragraph gutterBottom>
          We value competence, professionalism and team work, and strongly
          believe that freedom, transparency and friendship have an enormous
          impact on personal happiness as well as business success.
        </Typography>

        <CheckWrapper>✔️ Freedom </CheckWrapper>
        <Typography variant="body2" paragraph gutterBottom>
          We prioritise balance in our work, social life and health. To enable
          that we have great flexibility in how to use our time and income.
        </Typography>

        <CheckWrapper>✔️ Transparency</CheckWrapper>
        <Typography variant="body2" paragraph gutterBottom>
          We try to be as clear and straightforward as possible regarding our
          ways of working and we are always open to discuss any issue and listen
          to suggestions over a cup of coffee.
        </Typography>

        <CheckWrapper>✔️ Friendship </CheckWrapper>
        <Typography variant="body2" paragraph gutterBottom>
          Being part of a team of reliable, trustworthy and fun people makes
          everything easy and joyful.
        </Typography>
      </VisionSectionContent>
    </VisionSectionContentWrapper>
  </VisionSectionWrapper>
)

export default VisionSection
