import React from 'react'
import { Typography } from '@material-ui/core'
import {
  AboutSectionWrapper,
  AboutSectionContent,
  ImageWrapper,
  ImageOne,
  ImageTwo,
  AboutTextSectionContent
} from './about.styled'
import team1 from '../../../../assets/team1.png'
import team2 from '../../../../assets/team2.png'

const About = () => (
  <AboutSectionWrapper id={'aboutUs'}>
    <AboutSectionContent>
      <AboutTextSectionContent>
        <Typography variant="h5" gutterBottom>
          A little about us
        </Typography>

        <Typography variant="body2" paragraph gutterBottom>
          In Probisma, we are a mix of up-coming stars, experienced developers
          and agile experts.
        </Typography>

        <Typography variant="body2" paragraph gutterBottom>
          We believe in cross-functional expertise as a method to continuously
          move forward, exploring and learning new techniques with curiosity.
        </Typography>

        <Typography variant="body2" paragraph gutterBottom>
          Our team of front-end, back-end and fullstack developers cover a large
          span of techniques: Mobile Development, .NET, Java, JavaScript and
          Node.js, to mention a few.
        </Typography>
      </AboutTextSectionContent>
      <ImageWrapper>
        <ImageOne src={team1} />
        <ImageTwo src={team2} />
      </ImageWrapper>
    </AboutSectionContent>
  </AboutSectionWrapper>
)

export default About
