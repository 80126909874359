import React from 'react'
import styled from 'styled-components'
import { BasePage } from '../utils/page'
import { TopSection } from './sections/topSection/topSection.section'
import AboutSection from './sections/about/about.section'
import VisionSection from './sections/vision/vision.section'
import CustomersSection from './sections/customers/customers.section'
import ContactSection from './sections/contact/contact.section'
import BgDark from '../../assets/bg2x.png'

// With only a few styled components i think we can have them in the
// same file but if it are getting more we should consider breaking
// it out in a own file ex *.styled.tsx
const HomePageWrapper = styled.div``

const Footer = styled.div`
  height: 50px;
  background: url(${BgDark});
  display: flex;
  color: rgb(99, 99, 99);
  justify-content: center;
  align-items: center;
`

export const HomePage = () => {
  return (
    <BasePage>
      <HomePageWrapper>
        <TopSection />
        <AboutSection />
        <VisionSection />
        <CustomersSection />
        <ContactSection />
      </HomePageWrapper>
      <Footer>© 2023 | Probisma</Footer>
    </BasePage>
  )
}
