import styled from 'styled-components'
import { IconButton } from '@material-ui/core'
import { device } from '../../../theme'

export const MovileNavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #303030;
  width: 100%;
  margin-top: -70px;
  justify-content: flex-end;
`

export const MobileDrawer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 250px;
  height: 100vh;
  right: -250px;
  top: 0;
  background-color: #1d1d1d;
  transition: transform 300ms ease-in-out;
  z-index: 11;

  ${(p: { open: boolean }) =>
    p.open &&
    `
    transform: translate(-250px)
  `}
`

export const MobileDrawerBackground = styled.div`
  opacity: 0;
  visibility: hidden;
  top: 0;
  position: fixed;
  transition: opacity 0.5s ease-in-out;
  z-index: 10;
  right: 0;
  ${(p: { open: boolean }) =>
    p.open &&
    `
      visibility: visible;
      width: 100%;
      max-width:100%; 
      height: 100vh;
      background-color: #1d1d1d;
      opacity:0.6;
    `}
`

export const MobileMenuButton = styled(IconButton)`
  && {
    color: #969696;
    height: 60px;
    width: 60px;
    && svg {
      padding-top: 7px;
      font-size: 1.6em;
    }
    @media ${device.mobileL} {
      font-size: 1.5em && svg {
        padding-top: 0px;
        font-size: 1.5em;
      }
    }
  }
`

export const MobileLink = styled.div`
  padding-top: 10px;
  font-size: 1.3em;
`
