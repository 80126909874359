import * as React from 'react'
import { debounce } from '../../utils/debounce'

interface ResponsivProps {
  mobile?: boolean
  largeMobile?: boolean
  tablet?: boolean
  desktop?: boolean
}
interface ResponsivState {
  width: number
}
export class ResponsivSize extends React.Component<
  ResponsivProps,
  ResponsivState
> {
  constructor(props: ResponsivProps) {
    super(props)
    this.debouncedHandleWindowResize = debounce(
      this.debouncedHandleWindowResize.bind(this),
      200
    ) as () => Promise<void>

    this.state = {
      width: window.innerWidth
    }
  }

  public debouncedHandleWindowResize() {
    this.setState({ width: window.innerWidth })
  }

  componentDidMount() {
    window.addEventListener('resize', this.debouncedHandleWindowResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedHandleWindowResize)
  }

  render() {
    const { width } = this.state
    const { mobile, largeMobile, tablet, desktop } = this.props
    const isMobile = width < 480 && mobile
    const isLargeMobile = width >= 480 && width < 650 && largeMobile
    const isTablet = width >= 650 && width < 1024 && tablet
    const isDesktop = width >= 1024 && desktop

    return isMobile || isLargeMobile || isTablet || isDesktop
      ? this.props.children
      : null
  }
}
