import styled, { css } from 'styled-components'
import { useScrollState } from '../../hooks/useScroll'
import { device } from '../../theme'

export const NavigationWrapper = styled.div`
  background-image: url(${(p: { img: string }) => p.img});
  background-repeat: repeat-x;
  background-position: bottom center;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  max-width: 100%;
  height: 100px;
  z-index: 1000;

  @media ${device.mobileM} {
    height: 150px;
  }
`

export const ProbismaLogo = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: transform 0.3s ease-in-out;
  z-index: 9;
  svg {
    width: 180px;
    @media ${device.mobileS} {
      width: 180px;
    }
  }
  ${(p: { scroll: useScrollState }) =>
    p.scroll.scrollY > 30
      ? `
    transform: translateX(-50%) translateY(5px)
  `
      : `
    transform: translateX(-50%) translateY(65px)
  `}
`
export const LogoLink = styled.a``

export const NavLink = styled.a`
  font-size: 1.1em;
  font-weight: 600;
  margin: 0 15px -3px;
  padding-bottom: 5px;
  z-index: 10;
  ${({ href }) => (window.location.hash === href ? LinkActive : LinkInactive)}
`

const LinkActive = css`
  color: #828282;
  text-decoration: none;
  border-bottom: 3px solid #828282;
`

const LinkInactive = css`
  color: #4f4f4f;
  text-decoration: none;
`
