/* tslint:disable */
export const debounce = (callback: Function, time: number) => {
  let interval: NodeJS.Timer | null | number;;
  return (...args: Object[]) => {
    clearTimeout(interval as NodeJS.Timer);
    interval = setTimeout(() => {
      interval = null;
      callback(...args);
    }, time);
  };
};
/* tslint:enable */
