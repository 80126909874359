import { PaletteOptions } from '@material-ui/core/styles/createPalette'

const typography = {
  fontFamily: 'Maven Pro',
  h4: {
    fontWeight: 600
  },
  h5: {
    fontWeight: 600
  },
  h6: {
    fontWeight: 600
  }
}

const palette = {
  type: 'dark',
  primary: {
    light: '#696969',
    main: '#969696',
    dark: '#ababab'
  },
  secondary: {
    light: '#558d09',
    main: '#7aca0d',
    dark: '#94d43d'
  }
} as PaletteOptions

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`
}

export const materialUiTheme = {
  palette,
  typography
}
