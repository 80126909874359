import styled from 'styled-components'

export const Spacer = styled.div`
  height: ${(p: { size: 'xlarge' | 'large' | 'small' | undefined }) => {
    switch (p.size) {
      case 'xlarge':
        return '100px'
      case 'large':
        return '50px'
      default:
        return '20px'
    }
  }}};
`
