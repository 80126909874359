import React from 'react'
import styled from 'styled-components'
import { Navigation } from '../../components/navigation/navigation'

const PageWrapper = styled.div``

export const BasePage: React.FC = ({ children }) => (
  <PageWrapper>
    <Navigation />
    {children}
  </PageWrapper>
)
