import styled from 'styled-components'
import BgDark from '../../../../assets/bg2x.png'

export const ContactSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  transform: skew(0deg, 2deg);
  background: url(${BgDark});
  position: relative;
  margin-top: 50px;
`

export const ContactContent = styled.div`
  transform: skew(0deg, -2deg);
  max-width: 512px;
  padding: 50px 20px 100px 20px;
  color: #fff;
`
