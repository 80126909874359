import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { AppContainer } from './App.styled'
import { Head } from './page/utils/head'
import { HomePage } from './page/home-page/home-page'
import { GlobalStyles } from './index.styled'
import { createMuiTheme } from '@material-ui/core/styles'
import { materialUiTheme } from './theme'
import { ThemeProvider } from '@material-ui/core'

const theme = createMuiTheme(materialUiTheme)

export const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppContainer>
        <GlobalStyles></GlobalStyles>
        <Head></Head>
        <Router>
          <Route path="/" exact component={HomePage} />
        </Router>
      </AppContainer>
    </ThemeProvider>
  )
}
