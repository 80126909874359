import styled, { css } from 'styled-components'
import BgDark from '../../../../assets/bg2x.png'

interface BgProps {
  angle: number
  offset: number
}

export const CustomersWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`

export const CustomerBg = styled.div`
  position: absolute;
  left: 0;
  background-image: url(${BgDark});
  opacity: 0.05;
  width: 100%;
  ${(p: BgProps) =>
    css`
      transform: skew(0deg, ${p.angle}deg);
      top: ${p.offset}%;
      height: calc(100% - ${p.offset}%);
    `};
`

export const CustomersContent = styled.div`
  max-width: 1024px;
  padding: 100px 20px;
`
