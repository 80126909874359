import styled from 'styled-components'
import Bg from '../../../../assets/bg2x.png'
import { device } from '../../../../theme'

export const VisionSectionWrapper = styled.div`
  transform: skew(0deg, -2deg);
  background: url(${Bg}) #000;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const VisionImageWrapper = styled.div`
  background-image: url(${(p: { img: string }) => p.img});
  background-position: -150px bottom;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;

  flex-basis: 50%;
  height: 600px;
  overflow: hidden;
  visibility: hidden;
  display: none;

  @media ${device.laptop} {
    visibility: visible;
    display: block;
  }
`

export const VisionSectionContentWrapper = styled.div`
  flex-basis: 100%;

  @media ${device.tablet} {
    flex-basis: 70%;
  }

  @media ${device.laptop} {
    flex-basis: 50%;
  }
`

export const VisionSectionContent = styled.div`
  transform: skew(0deg, 2deg);
  color: #fff;
  padding: 100px 20px;

  @media ${device.tablet} {
    padding: 100px 0;
  }

  @media ${device.laptop} {
    padding: 100px 50px;
    max-width: 410px;
  }
`

export const CheckWrapper = styled.div`
  font-size: 0.875rem;
  font-family: Maven Pro;
  font-weight: 600;
  line-height: 1.43;
`
