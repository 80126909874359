import React, { Children, FC, useState } from 'react'
import {
  MovileNavigationWrapper,
  MobileDrawer,
  MobileLink,
  MobileMenuButton,
  MobileDrawerBackground
} from './mobile.styled'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'

export const MobileNavigation: FC = props => {
  const [isOpen, toogleMobileMenu] = useState(false)

  return (
    <MovileNavigationWrapper>
      <MobileMenuButton onClick={() => toogleMobileMenu(!isOpen)}>
        <MenuIcon />
      </MobileMenuButton>
      <MobileDrawer open={isOpen}>
        <MobileMenuButton onClick={() => toogleMobileMenu(!isOpen)}>
          <CloseIcon />
        </MobileMenuButton>
        {isOpen &&
          Children.map(props.children, link => (
            <MobileLink onClick={() => toogleMobileMenu(false)}>
              {link}
            </MobileLink>
          ))}
      </MobileDrawer>
      <MobileDrawerBackground open={isOpen} />
    </MovileNavigationWrapper>
  )
}
