import styled from 'styled-components'
import { device } from '../../../../theme'
import { Typography } from '@material-ui/core'

export const TopSectionWrapper = styled.section`
  background-repeat: repeat-x;
  height: 100vh;
`

export const TopSectionImage = styled.div`
  background-image: url(${(p: { img: string }) => p.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`

/* TODO: get the spacing values from material ui */
export const TopSectionContentWrapper = styled.div`
  max-width: 1024px;
  color: #fff;
  margin-top: 130px;
  @media ${device.mobileL} {
    margin-top: 0px;
  }
`

export const TopSectionContent = styled.div`
  padding: 0 50px;
  text-align: center;

  @media ${device.mobileM} {
  }

  @media screen and (min-width: 1024px) {
    text-align: left;
    padding: 0;
    width: 50%;
  }
`

export const HeaderTitle = styled(Typography)`
  && {
    font-size: 1.2rem;
    @media ${device.mobileM} {
      font-size: 2.125rem;
    }
  }
`
