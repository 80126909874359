import React from 'react'
import {
  NavigationWrapper,
  NavLink,
  ProbismaLogo,
  LogoLink
} from './navigation.styled'
import { ResponsivSize } from '../responsiv-size/responsivSize.component'
import { MobileNavigation } from './mobile/mobile.navigation'
import { DesktopNavigation } from './desktop/desktop.styled'
import { ReactComponent as Logo } from '../../assets/Probisma.svg'
import { useScroll } from '../../hooks/useScroll'
import menuImage from '../../assets/menu-bg2x.png'

const ResponsivNavigation: React.FC = props => (
  <>
    <ResponsivSize tablet={true} largeMobile={true} mobile={true}>
      <MobileNavigation>{props.children}</MobileNavigation>
    </ResponsivSize>
    <ResponsivSize desktop={true}>
      <DesktopNavigation>{props.children}</DesktopNavigation>
    </ResponsivSize>
  </>
)

export const Navigation = () => {
  const scroll = useScroll(100)

  return (
    <NavigationWrapper img={menuImage} id="navigation-wrapper">
      <ResponsivNavigation>
        <NavLink href={'#aboutUs'}> About </NavLink>
        <NavLink href={'#vision'}> Vision </NavLink>
        <NavLink href={'#customers'}> Customers </NavLink>
        <NavLink href={'#contact'}> Contact </NavLink>
      </ResponsivNavigation>
      <ProbismaLogo id="logo" scroll={scroll}>
        <LogoLink href={'#home'}>
          <Logo />
        </LogoLink>
      </ProbismaLogo>
    </NavigationWrapper>
  )
}
