import styled from 'styled-components'

export const DesktopNavigation = styled.div`
  padding: 0px 30px;
  margin-top: -20px;
  border-bottom: 1px solid #303030;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 2;
`
