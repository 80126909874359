import { useState, useEffect } from 'react';
import { debounce } from '../utils/debounce';

export interface useScrollState {
  scrollY: number
  scrollX: number
  scrollDirection: string
}

export function useScroll(delay: number = 200): useScrollState {
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const [scrollY, setScrollY] = useState(document.body.getBoundingClientRect().top);
  const [scrollX, setScrollX] = useState(document.body.getBoundingClientRect().left);
  const [scrollDirection, setScrollDirection] = useState();

  const listener = (e:Event) => {
    setScrollY(-document.body.getBoundingClientRect().top);
    setScrollX(document.body.getBoundingClientRect().left);
    setScrollDirection(lastScrollTop > -document.body.getBoundingClientRect().top ? 'down' : 'up');
    setLastScrollTop(-document.body.getBoundingClientRect().top);
  };

  useEffect(() => {
    window.addEventListener('scroll', debounce(listener, delay));
    return () => {
      window.removeEventListener('scroll', listener);
    };
  });

  return {
    scrollY,
    scrollX,
    scrollDirection,
  };
}