import { createGlobalStyle } from 'styled-components'
import BgLight from './assets/bg-light2x.png'

export const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Maven+Pro&display=swap');
  
  body {
    font-family: 'Maven Pro', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position:absolute;
    width: 100%;
    max-width:100%; 
    height: 100%;
    overflow-x:hidden;
    margin: 0;
    padding: 0;
    background: url(${BgLight})
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  html {
    scroll-behavior: smooth;
  }
`
