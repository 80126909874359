import React from 'react'
import {
  TopSectionImage,
  TopSectionWrapper,
  TopSectionContentWrapper,
  TopSectionContent,
  HeaderTitle
} from './topSection.styled'
import topImage from '../../../../assets/main-bg.jpg'
import { Button, Typography } from '@material-ui/core'

export const TopSection = () => (
  <TopSectionWrapper id={'home'}>
    <TopSectionImage img={topImage}>
      <TopSectionContentWrapper>
        <TopSectionContent>
          <HeaderTitle variant="h4" gutterBottom>
            Freedom, Transparency, Friendship and IT
          </HeaderTitle>

          <Typography variant="body1" gutterBottom paragraph>
            Probisma is a home for IT-consultants who value freedom, growth and
            collaboration. We are passionate about solving problems for our
            customers and pairing our brilliant consultants with fun and
            challenging assignments. Think we’d be a good match? We’d love to
            hear from you!
          </Typography>

          <Button variant="outlined" size="large" href="/#contact">
            Contact us
          </Button>
        </TopSectionContent>
      </TopSectionContentWrapper>
    </TopSectionImage>
  </TopSectionWrapper>
)
