import React from 'react'
import { Typography } from '@material-ui/core'
import {
  CustomersWrapper,
  CustomersContent,
  CustomerBg
} from './customers.styled'
import { Spacer } from '../../../utils/spacer'

const Customers = () => {
  return (
    <CustomersWrapper id={'customers'}>
      <CustomerBg angle={-2} offset={0} />
      <CustomerBg angle={2} offset={50} />
      <CustomerBg angle={2} offset={60} />
      <CustomersContent>
        <Typography variant="h5" gutterBottom>
          Our Customers
        </Typography>

        <Typography variant="body2" gutterBottom>
          Since 2016, we have been building a solid network in the Stockholm
          tech market. This enables us to always offer interesting and
          challenging assignments to our consultants and find the perfect fit
          for our clients. We have worked with Kivra, Telenor, Spotify, ICA and
          Nobina, to name a few.
        </Typography>

        <Spacer size="small" />

        <Typography variant="h6">
          Brokers & other consultancy companies
        </Typography>

        <Typography variant="body2" gutterBottom>
          We have assignments through our own customer network. Our main
          strategy is to work closely and collaborate with the best consultancy
          brokers and companies. This way we can reach all consultant buyers in
          Stockholm.
        </Typography>

        <Spacer size="small" />

        <Typography variant="h6">Friends</Typography>

        <Typography variant="body2" gutterBottom>
          We live in a small world, and an even smaller city, so we often get
          tips and leads from colleagues and friends in our network. We will
          work with anyone who seems like a good fit!
        </Typography>
      </CustomersContent>
    </CustomersWrapper>
  )
}

export default Customers
