import styled from 'styled-components'
import { device } from '../../../../theme'

export const AboutSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AboutSectionContent = styled.div`
  padding: 150px 0px;
  max-width: 1024px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const AboutTextSectionContent = styled.div`
  padding: 15px 20px;
  max-width: 100%;

  @media ${device.tablet} {
    width: 70%;
    padding: 20px 15px;
  }

  @media ${device.laptop} {
    width: 482px;
    padding: 20px 15px;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 50px 0px;

  @media ${device.tablet} {
    width: 482px;
    padding: 20px 15px 50px 15px;
  }
`
export const ImageOne = styled.img`
  width: 70%;
  align-self: flex-start;
`
export const ImageTwo = styled.img`
  align-self: flex-end;
  width: 50%;
  right: 0;
  z-index: 10;
  position: absolute;
  bottom: 0;
`
