import React from 'react'
import { ContactContent, ContactSectionWrapper } from './contact.styled'
import { Typography, Button, Link } from '@material-ui/core'
import { Spacer } from '../../../utils/spacer'

const Contact = () => (
  <ContactSectionWrapper id={'contact'}>
    <ContactContent>
      <Typography variant="h5" gutterBottom>
        Contact
      </Typography>
      <Typography variant="body2" gutterBottom>
        We sit in a digital landscape at No.18, set above the main hall of
        Stockholm Central Station, which means that our consultants and friends
        can easily come by for a coffee and a chat.
        <Spacer size="small" />
        If you are interested in working at Probisma or just want to find out a
        little more, please don’t hesitate to reach out at{' '}
        <Link href="mailto:contact@probisma.se">contact@probisma.se</Link>.
      </Typography>

      <Spacer size="small" />
      <Button
        variant="contained"
        size="small"
        href="mailto:contact@probisma.se"
        fullWidth
      >
        Send a message
      </Button>
    </ContactContent>
  </ContactSectionWrapper>
)

export default Contact
